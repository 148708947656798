import {
  ActionButtonsContainerStyles,
  ActionButtonStyles,
} from "@components/ReusableComponents/ImageSelectorContainer/styles";
import { css } from "@emotion/react";

export const CourseImageStyles = (
  { courseEdit },
  disabled,
  actionButtonsInline,
  isLoading = false,
) => css`
  position: relative;
  width: 100%;
  min-height: 275px;
  aspect-ratio: 3/2;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .upload-overlay {
    width: 100%;
    height: 100%;
    ${ActionButtonsContainerStyles({ disabled, actionButtonsInline })}
    position: absolute;
    color: white;
    background-color: ${courseEdit.courseImageOverlayBackground}; // TODO: Background color is different from imageSelector.backgroundOverlayHoverColor
    opacity: ${isLoading ? 1 : 0};
    transition: opacity 0.2s linear;

    &:hover {
      opacity: ${disabled ? 0 : 1};
    }

    .input-field {
      position: absolute;
      appearance: initial;
      opacity: 0;
    }

    button {
      ${ActionButtonStyles({ inline: actionButtonsInline, isLoading })}
      cursor: pointer;
    }
  }
`;
