import { getContrastColor, getProtectedColor, PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";
import Color from "color";

export default (colors: PalletThemeColors): ThemePlusTypes["alertBox"] => {
  const protectColorContrast = getProtectedColor(
    Color(colors.primary.lightest).alpha(0.25).string(),
    colors,
  );
  const protectedBackgroundColor = protectColorContrast(
    "darker",
    Color(colors.primary.lightest).alpha(0.25).string(),
  );
  const textColorWithContrast = getContrastColor(protectedBackgroundColor);

  return {
    info: {
      backgroundColor: protectedBackgroundColor,
      color: textColorWithContrast,
    },
    warning: {
      backgroundColor: Color(colors.orange.base).alpha(0.15).string(),
    },
    outlineButton: {
      backgroundColor: colors.white,
      hoverColor: colors.primary.light,
    },
  };
};
